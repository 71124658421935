import { useEffect, useRef } from 'react';
import { Pagination, Autoplay } from 'swiper/modules';
import styles from './style.module.css';
import { useInView } from 'react-intersection-observer';
import { sliderCss } from './sliderCss';

const PARAMS = {
  modules: [Pagination, Autoplay],
  slidesPerView: 1,
  spaceBetween: 50,
  navigation: false,
  pagination: {
    clickable: true
  },
  autoplay: {
    delay: 5000,
    disableOnInteraction: false,
    waitForTransition: true
  },
  injectStyles: [sliderCss]
};

export const SliderSection = (props) => {
  const { slides, hasVideo, sliderRef, SlideComponent, sectionName } = props;
  const swiperEl = useRef(null);

  const { ref, inView } = useInView({
    threshold: 0
  });

  useEffect(() => {
    try {
      Object.assign(swiperEl.current, PARAMS);

      swiperEl.current.initialize();

      swiperEl.current.swiper.autoplay.stop();
    } catch (e) {
      console.error(e);
    }
  }, []);

  useEffect(() => {
    try {
      if (inView) {
        swiperEl.current.swiper.autoplay.start();

        return () => {
          swiperEl.current.swiper.autoplay.stop();
        };
      }
    } catch (e) {
      console.error(e);
    }
  }, [inView]);

  const handleSlideChange = (event) => {
    if (hasVideo) {
      const [swiper] = event.detail;
      const videos = document.querySelectorAll(`#${sectionName} [data-video-slide]`);

      videos.forEach(async (video, index) => {
        if (index === swiper.activeIndex - 1) {
          try {
            await video.play();
          } catch (e) {
            console.error("Video is not supported in your browser");
          }


          return;
        }

        video.currentTime = 0;
        video.pause();
      });
    }
  };

  useEffect(() => {
    if (hasVideo) {
      sliderRef.current.addEventListener('swiperslidechange', handleSlideChange);
    }

    return () => {
      if (hasVideo) {
        sliderRef.current?.removeEventListener('swiperslidechange', handleSlideChange);
      }
    };
  }, []);

  return (
    <div ref={ref} className={styles.slider}>
      <div className={styles.sliderHeader}>
        <span className={styles.title}>{sectionName}</span>
      </div>

      <swiper-container ref={swiperEl} init="false">
        {slides.map((slide) => {
          return (
            <swiper-slide key={slide.key}>
              <SlideComponent {...slide} />
            </swiper-slide>
          );
        })}
      </swiper-container>
    </div>
  );
};
